import { FC, useEffect } from "react";
import { Modal } from "antd";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import StartBlitzTournamentV2 from "./StartBlitzTournamentV2";
import FinishBlitzTournamentV2 from "./FinishBlitzTournamentV2";
import RefereeBonusTransaction from "./RefereeBonusTransaction";

import NewGame from "./NewGame";
import AccountCreationDeposit from "./AccountCreationDeposit";
import TriumphDeposit from "./TriumphDeposit";
import DepositTransaction from "./Deposit";
import WithdrawalTransaction from "./WithDrawal";
import WithdrawalRedepositTransaction from "./WithdrawalRedeposit";
import {
  appUserPublicSelect,
  getAppUserPublicDetails,
} from "src/features/appUsersPublic/appUserPublicSlice";
import GroupTournamentDetails from "../tournament/tournamentDetailPage/AyncGroup";
import ChallengeEntry from "./ChallengeEntry";

interface Props {
  selectedRow: BalanceTransaction | ChallengeEntryTrx | null;
  onClose: () => void;
}

const RenderModalContent: FC<{
  selectedRow: BalanceTransaction | ChallengeEntryTrx | null;
  onClose?: () => void;
  selectedUser: AppUserPublic | null;
}> = ({ selectedRow, selectedUser, onClose }) => {
  if (!selectedUser) return <></>;
  if (!selectedRow) return <></>;

  switch (selectedRow.type) {
    case "start-blitz":
      if (selectedRow.version === "2" || selectedRow.version === "2.4") {
        return <StartBlitzTournamentV2 row={selectedRow} user={selectedUser} />;
      } else {
        return <></>;
      }
    case "finish-blitz":
      if (selectedRow.version === "2" || selectedRow.version === "2.4") {
        return (
          <FinishBlitzTournamentV2 row={selectedRow} user={selectedUser} />
        );
      } else {
        return <></>;
      }

    case "referrer-bonus":
      return <RefereeBonusTransaction row={selectedRow} user={selectedUser} />;
    case "start-group-tournament":
    case "finish-group-tournament":
    case "reverse-start-group-tournament":
      return (
        <GroupTournamentDetails
          onClose={onClose}
          isModal={true}
          tournamentId={selectedRow.tournamentId}
          game={selectedRow.gameId}
          transaction={selectedRow}
        />
      );
    case "new-game":
      return <NewGame row={selectedRow} user={selectedUser} />;
    case "account-creation-deposit":
      return <AccountCreationDeposit row={selectedRow} user={selectedUser} />;
    case "triumph-deposit":
      return <TriumphDeposit row={selectedRow} user={selectedUser} />;
    case "deposit":
      return <DepositTransaction row={selectedRow} user={selectedUser} />;
    case "withdrawal":
      return <WithdrawalTransaction row={selectedRow} user={selectedUser} />;
    case "withdrawal-redeposit":
      return (
        <WithdrawalRedepositTransaction row={selectedRow} user={selectedUser} />
      );
    case "challenge-entry":
      return <ChallengeEntry user={selectedUser} trx={selectedRow} />;
    default:
      return <></>;
  }
};

const UserBalanceTransactionDetails: FC<Props> = ({ selectedRow, onClose }) => {
  const { usersPublic } = useAppSelector(appUserPublicSelect);
  const dispatch = useAppDispatch();

  const appUserId = selectedRow?.appUserUid;
  const selectedUser = appUserId ? usersPublic[appUserId] : null;
  useEffect(() => {
    if (appUserId && !selectedUser) {
      dispatch(getAppUserPublicDetails(appUserId));
    }
  }, [selectedUser, appUserId, dispatch]);

  const getWidth = () => {
    switch (selectedRow?.type) {
      case "start-group-tournament":
      case "finish-group-tournament":
      case "challenge-entry":
        return "85vw";
      default:
        return 1000;
    }
  };
  return (
    <Modal
      title="Balance Transaction Details"
      centered
      open={!!selectedRow}
      onOk={onClose}
      onCancel={onClose}
      width={getWidth()}
    >
      <RenderModalContent
        onClose={onClose}
        selectedUser={selectedUser}
        selectedRow={selectedRow}
      />
    </Modal>
  );
};

export default UserBalanceTransactionDetails;
