import { Descriptions, Tag } from "antd";
import { FC } from "react";
import GameName from "src/shared/components/GameName/gameName";
import Currency from "src/shared/components/currency/Currency";
import { dateFormatter } from "src/shared/config/constants";
import { createMetaDataArray } from "../utils/utils";

interface Props {
  trx: ChallengeEntryTrx;
  user: AppUserPublic;
}
const TransactionDetails: FC<Props> = ({ trx, user }) => {
  return (
    <Descriptions
      title="Transaction Details"
      layout="horizontal"
      colon
      column={1}
      bordered
      className="pb-2"
    >
      <Descriptions.Item label="ID">{trx.uid || "null"}</Descriptions.Item>
      <Descriptions.Item label="Trace ID">
        {trx.traceId || "null"}
      </Descriptions.Item>

      <Descriptions.Item label="App User username">
        {user.username || "null"}
      </Descriptions.Item>
      <Descriptions.Item label="App User ID">
        {trx.appUserUid || "null"}
      </Descriptions.Item>
      <Descriptions.Item label="Type">
        <Tag color="blue">{trx.type || "null"}</Tag>
      </Descriptions.Item>
      <Descriptions.Item label="Description">
        {trx.description || "null"}
      </Descriptions.Item>
      <Descriptions.Item label="Amount">
        {trx.amount ? (
          <Currency currency="cents-to-dollar" prefix="$" value={trx.amount} />
        ) : (
          "null"
        )}
      </Descriptions.Item>

      <Descriptions.Item label="Bonus Cash Amount">
        {trx.bonusCashAmount ? (
          <Currency
            currency="cents-to-dollar"
            prefix="$"
            value={trx.bonusCashAmount}
          />
        ) : (
          "null"
        )}
      </Descriptions.Item>

      <Descriptions.Item label="Game">
        <GameName id={trx.gameId || "null"} />
      </Descriptions.Item>

      <Descriptions.Item label="Created Date">
        {trx.createdAt ? dateFormatter(trx.createdAt) : "null"}
      </Descriptions.Item>
      <Descriptions.Item label="Transaction Date">
        {trx.transactionDate ? dateFormatter(trx.transactionDate) : "null"}
      </Descriptions.Item>
      {createMetaDataArray(trx).map((e) => (
        <Descriptions.Item label={e.label}>{e.value}</Descriptions.Item>
      ))}
    </Descriptions>
  );
};

export default TransactionDetails;
