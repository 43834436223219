import { Button, Modal, Row, message } from "antd";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import { axiosInstanceV2, environment, isTriumphEmployee } from "src/helpers";
import AddBalanceAmount from "./addBalance";
import AddBonusCash from "./addBonusCash";
import BanUnBanUser from "./banUser";
import AddGemstoUser from "./addGems";
import PassKyc from "./passKyc";
import FailKyc from "./failKyc";

interface Props {
  user?: AppUser & AppUserPublic;
  userState?: UserState;
  hideUserStateFields?: boolean;
  hideGoBack?: boolean;
}

const UserActionButtons: FC<Props> = ({
  user,
  userState,
  hideUserStateFields,
  hideGoBack,
}) => {
  const [isBalanceOpen, setIsBalanceOpen] = useState(false);
  const [isGemBalanceOpen, setIsGemBalanceOpen] = useState(false);
  const [isBonusCashOpen, setIsBonusCashOpen] = useState(false);
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();

  function onDebugClick() {
    if (user) {
      Modal.confirm({
        title: "Confirm remove ?",
        icon: <ExclamationCircleOutlined />,
        content: "By clicking confirm the user will be removed from debug ",
        okText: "Confirm",
        cancelText: "Back",
        onOk: async () => {
          try {
            const response = await axiosInstanceV2.post<{
              success: boolean;
              error: string;
            }>("/internal/users/debug_remove", { uidToRemove: user.uid });

            if (response.status === 200 && response.data) {
              messageApi.open({
                type: "success",
                content: "Success!",
              });
            } else {
              messageApi.open({
                type: "error",
                content: response.data.error || "Something went wrong!",
              });
            }
          } catch (error) {
            console.error(error);
            messageApi.open({
              type: "error",
              content: "Something went wrong!",
            });
          }
        },
        okCancel: true,
      });
    }
  }

  const isDebugRemoverEnabled =
    (environment === "debug" ||
      environment === "develop" ||
      environment === undefined) &&
    isTriumphEmployee();

  return (
    <Row className="user-action-wrap mb-2">
      {contextHolder}

      {!hideGoBack && (
        <Button type="ghost" className="mr-1" onClick={() => navigate(-1)}>
          Go Back
        </Button>
      )}
      {user && <PassKyc user={user} />}
      {user && <FailKyc user={user} />}
      {user && (
        <AddBalanceAmount
          open={isBalanceOpen}
          user={user}
          onClose={() => setIsBalanceOpen(false)}
        />
      )}
      {user && (
        <AddBonusCash
          user={user}
          open={isBonusCashOpen}
          onClose={() => setIsBonusCashOpen(false)}
        />
      )}
      {user && (
        <AddGemstoUser
          open={isGemBalanceOpen}
          user={user}
          onClose={() => setIsGemBalanceOpen(false)}
        />
      )}

      {user && !hideUserStateFields && (
        <BanUnBanUser userState={userState} user={user} />
      )}
      {isDebugRemoverEnabled && (
        <Button className="mr-1" type="primary" danger onClick={onDebugClick}>
          Remove User Debug
        </Button>
      )}

      <Button
        className="mr-1"
        type="primary"
        onClick={() => setIsBalanceOpen(true)}
      >
        Add to Balance
      </Button>
      <Button
        className="mr-1"
        type="primary"
        onClick={() => setIsBonusCashOpen(true)}
      >
        Add to Bonus Cash
      </Button>
      <Button
        className="mr-1"
        type="primary"
        onClick={() => setIsGemBalanceOpen(true)}
      >
        Add to Gem Balance
      </Button>
    </Row>
  );
};

export default UserActionButtons;
