import { Button, Col, Form, Input, Radio, Row } from "antd";
import { FC } from "react";
import { formSubmit } from "src/shared/config/constants";
import { SearchOutlined } from "@ant-design/icons";
import { SearchDataType } from "../search.types";
import { colNames, getCollection } from "src/collections";
import { getDocs, query, where } from "firebase/firestore";
import { getPhoto } from "src/helpers";

type FormType = {
  "user-search": string;
  type: "username" | "name" | "phoneNumber" | "uid";
};

interface Props {
  isFetching: (t: boolean) => void;
  setData: (data: SearchDataType[]) => void;
}
const UserSearchBar: FC<Props> = ({ isFetching, setData }) => {
  const [form] = Form.useForm<FormType>();
  const handleSubmit = formSubmit(form);

  function onChange(field: keyof FormType, value: string) {
    form.setFieldsValue({
      ...form.getFieldsValue(),
      [field]: value,
    });
  }

  const fetchUser = async (
    value: string,
    type: FormType["type"]
  ): Promise<Record<string, AppUser & AppUserPublic> | undefined> => {
    const search = value.trim();
    const userColRef = getCollection(colNames.appUsers);
    const publicUserColRef = getCollection(colNames.appUsersPublic);
    if (["uid", "name", "username"].includes(type)) {
      const publicUserQuery = query(
        publicUserColRef,
        where(type, "==", search)
      );

      const publicUserSnapshot = await getDocs(publicUserQuery);

      let data: Record<string, AppUser & AppUserPublic> = {};
      publicUserSnapshot.docs.forEach((d) => {
        if (d) {
          data[d.id] = { ...(data[d.id] || {}), ...d.data() };
        }
      });

      // additionally check if string partially match search
      if (Object.keys(data).length === 0) {
        const publicPartiallyMatchedUserQuery = query(
          publicUserColRef,
          where(type, ">=", search),
          where(type, "<=", search + "\uF8FF")
        );

        const publicPartiallyMatchedUserSnapshot = await getDocs(
          publicPartiallyMatchedUserQuery
        );

        publicPartiallyMatchedUserSnapshot.docs.forEach((d) => {
          if (d) {
            data[d.id] = {
              ...(data[d.id] || {}),
              ...d.data(),
            };
          }
        });
      }

      // if still no data check for firstname and lastname
      if (Object.keys(data).length === 0 && type === "name") {
        const splitted = search.trim().split(" ");
        const firstName = splitted[0];
        const lastName = splitted[1];

        const params = [];
        if (firstName && firstName.length > 0)
          params.push(where("firstName", "==", firstName));
        if (lastName && lastName.length > 0)
          params.push(where("lastName", "==", lastName));
        const publicFullMatchedUserQuery = query(publicUserColRef, ...params);

        const publicFullMatchedUserSnapshot = await getDocs(
          publicFullMatchedUserQuery
        );

        publicFullMatchedUserSnapshot.docs.forEach((d) => {
          if (d) {
            data[d.id] = {
              ...(data[d.id] || {}),
              ...d.data(),
            };
          }
        });
      }

      Object.values(data).map(async (e) => {
        const privateUserQuery = query(userColRef, where("uid", "==", e.uid));
        const privateUserSnapshot = await getDocs(privateUserQuery);
        privateUserSnapshot.docs.forEach((d) => {
          if (d) {
            data[d.id] = { ...(data[d.id] || {}), ...d.data() };
          }
        });
      });

      return data;
    }

    if (type === "phoneNumber") {
      const privateUserQuery = query(
        userColRef,
        where("phoneNumber", "==", search)
      );
      const privateUserSnapshot = await getDocs(privateUserQuery);
      let data: Record<string, AppUser & AppUserPublic> = {};

      privateUserSnapshot.docs.forEach((d) => {
        if (d) {
          data[d.id] = { ...(data[d.id] || {}), ...d.data() };
        }
      });

      Object.values(data).map(async (e) => {
        const publicUserQuery = query(
          publicUserColRef,
          where("uid", "==", e.uid)
        );
        const publicUserSnapshot = await getDocs(publicUserQuery);
        publicUserSnapshot.docs.forEach((d) => {
          if (d) {
            data[d.id] = { ...(data[d.id] || {}), ...d.data() };
          }
        });
      });
      return data;
    }
  };

  const onSave = async (data: FormType) => {
    isFetching(true);
    setData([]);
    try {
      const response = await fetchUser(data["user-search"], data.type);
      if (response && Object.keys(response).length !== 0) {
        setData(
          Object.values(response).map((e) => ({
            title: e.username,
            description: `username: ${e.username || "-"}\n
        uid: ${e.uid}`,
            url: `users/${e.uid}`,
            icon: getPhoto(e),
          }))
        );
      }
    } catch (error) {
    } finally {
      isFetching(false);
    }
  };
  return (
    <Form
      name="app user search"
      form={form}
      autoComplete="off"
      onSubmitCapture={() => handleSubmit(onSave)}
    >
      <Row>
        <Col span={24}>
          <Form.Item
            name="user-search"
            rules={[
              {
                required: true,
                message: "Search value is required",
              },
            ]}
          >
            <Input
              name="user-search"
              className="user-search-input-v2"
              size="middle"
              autoFocus={true}
              addonAfter={
                <SearchOutlined onClick={() => handleSubmit(onSave)} />
              }
              onChange={(event) => onChange("user-search", event.target.value)}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="type" initialValue="username">
            <Radio.Group
              name="type"
              onChange={(event) => onChange("type", event.target.value)}
            >
              <Radio value="username">Search by Username</Radio>
              <Radio value="name">Search by Name</Radio>
              <Radio value="phoneNumber">Search by Phone</Radio>
              <Radio value="uid">Search by UID</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Button type="primary" htmlType="submit" title="Search">
              Search
            </Button>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default UserSearchBar;
