import { Modal, message, Button } from "antd";
import { FC } from "react";
import { axiosInstanceV2 } from "src/helpers";
import { ExclamationCircleOutlined } from "@ant-design/icons";

interface Props {
  user: AppUser & AppUserPublic;
}
const FailKyc: FC<Props> = ({ user }) => {
  const [messageApi, contextHolder] = message.useMessage();

  function onClick() {
    Modal.confirm({
      title: "Confirm fail kyc ?",
      icon: <ExclamationCircleOutlined />,
      content: "By clicking confirm the user will fail kyc ",
      okText: "Confirm",
      cancelText: "Back",
      okCancel: true,
      onOk: async () => {
        try {
          const response = await axiosInstanceV2.post(
            `/internal/users/fail_kyc`,
            { appUserUid: user.uid }
          );
          if (response.status === 200 && response.data) {
            messageApi.open({
              type: "success",
              content: "Success!",
            });
          } else {
            messageApi.open({
              type: "error",
              content: response.data.error || "Something went wrong!",
            });
          }
        } catch (error) {
          messageApi.open({
            type: "error",
            content: "Something went wrong!",
          });
        }
      },
    });
  }

  return (
    <>
      {contextHolder}

      <Button className="mr-1" danger type="primary" onClick={onClick}>
        Fail Kyc
      </Button>
    </>
  );
};

export default FailKyc;
