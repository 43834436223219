import {
  Collapse,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from "antd";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useMemo, useState } from "react";
import { eventConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import { dateFormatter } from "src/shared/config/constants";
import UserAbuseEventModal from "./eventsModal";
import { EventType } from "src/shared/abuse";

interface Props {
  user: AppUser & AppUserPublic;
}
const AbuseEvents: FC<Props> = ({ user }) => {
  const [userAbuseEvents, setUserAbuseEvents] = useState<{
    [x: string]: UserEvent;
  }>();

  const [selectedEvent, setSelectedEvent] = useState<UserEvent | null>(null);

  useEffect(() => {
    const userRef = collection(db, generateFirestorePath("appUsers"));

    const userDoc = doc(userRef, user.uid);

    const abuseEventsRef = collection(
      userDoc,
      generateFirestorePath("abuseEvents")
    ).withConverter(eventConverter);

    const unsubUserEventsCol = onSnapshot(abuseEventsRef, (colSnap) => {
      const defs: typeof userAbuseEvents = {};
      for (const eventDefSnap of colSnap.docs) {
        const eventDefId = eventDefSnap.id;
        const eventDef = eventDefSnap.data();
        defs[eventDefId] = eventDef;
      }
      setUserAbuseEvents(defs);
    });
    return () => {
      unsubUserEventsCol();
    };
  }, [user.uid]);

  const pageinationConfig: TablePaginationConfig = {
    defaultPageSize: 100,
    className: "normal-option-size",
  };

  const columns: TableColumnsType<UserEvent> = [
    {
      title: "Event ID",
      dataIndex: "uid",
      key: "uid",
      render: (uid, record) => (
        <Typography.Link
          onClick={(e) => {
            e.stopPropagation();
            setSelectedEvent(record);
          }}
          className="mr-1"
        >
          {uid}
        </Typography.Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "eventType",
      key: "eventType",
    },
    {
      title: "Sequence id",
      dataIndex: "sequenceId",
      key: "sequenceId",
      sorter: (a, b) => a.sequenceId - b.sequenceId,
    },
    {
      title: "Note Contents",
      dataIndex: "noteContents",
      key: "noteContents",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      defaultSortOrder: "descend",
      render: (date) => dateFormatter(date),
    },
  ];

  const dataSource = useMemo(() => {
    return Object.values(userAbuseEvents ?? {}).filter((event) =>
      [
        EventType.AdminBanAccount,
        EventType.AdminUnbanAccount,
        EventType.LocalDeviceFraud,
      ].includes(event.eventType)
    );
  }, [userAbuseEvents]);

  return (
    <>
      <UserAbuseEventModal
        selectedRow={selectedEvent}
        onClose={() => setSelectedEvent(null)}
      />
      {dataSource.length > 0 && (
        <Collapse ghost>
          <Collapse.Panel
            header={
              <Typography.Title level={5}>User Abuse Events</Typography.Title>
            }
            key="1"
          >
            <Table
              columns={columns}
              loading={userAbuseEvents === null}
              rowKey="id"
              dataSource={dataSource}
              pagination={pageinationConfig}
              className="mobile-table-small bottom"
            />
          </Collapse.Panel>
        </Collapse>
      )}
    </>
  );
};

export default AbuseEvents;
