import { Button, Modal, Table, TableColumnsType, message } from "antd";
import { FC } from "react";
import { dateFormatter } from "src/shared/config/constants";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { axiosInstanceV2 } from "src/helpers";
import ReplayVideo from "./ReplayVideo";

interface Props {
  challenge: HistoricChallengeResult["entries"][0] | null;
  onClose: () => void;
  onSuccessFullRecredit: () => void;
}
const ChallengeTable: FC<Props> = ({
  challenge,
  onClose,
  onSuccessFullRecredit,
}) => {
  const [messageApi, contextHolder] = message.useMessage();

  const columns: TableColumnsType<ChallengeEntry> = [
    {
      title: "Tournament Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Seed",
      dataIndex: "seed",
      key: "seed",
    },
    {
      title: "Replay",
      dataIndex: "replayPath",
      key: "replayPath",
      render: (value, record) => {
        return value ? <ReplayVideo replay={value} /> : "-";
      },
    },
    {
      title: "Entry Type",
      dataIndex: "entryType",
      key: "entryType",
    },
    {
      title: "Entry Fee Paid",
      dataIndex: "entryFeePaid",
      key: "entryFeePaid",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (value) => dateFormatter(value),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      width: 130,
      fixed: "right",
      render: (id: string) => (
        <Button
          onClick={() =>
            Modal.confirm({
              title: `Confirm Recredit ?`,
              icon: <ExclamationCircleOutlined />,
              content: `By clicking confirm the player will get a recredit`,
              okText: "Confirm",
              cancelText: "Back",
              onOk: () => onRecredit(id),
              okCancel: true,
            })
          }
        >
          Re Credit
        </Button>
      ),
    },
  ];

  async function onRecredit(id: string) {
    try {
      const response = await axiosInstanceV2.post(
        `/challenges/admin/refund?entryId=${id}`,
        undefined,
        { headers: { "App-Id": "4y8eFmPpFUB4WXsjoSD6" } }
      );

      if (response.status === 200 && response.data) {
        messageApi.open({
          type: "success",
          content: "Success!",
          onClose: () => onSuccessFullRecredit(),
        });
      } else {
        messageApi.open({
          type: "error",
          content: "Something went wrong!",
        });
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong!",
      });
    }
  }

  return (
    <>
      <Modal
        title="Challenge Entry Details"
        centered
        open={!!challenge}
        onOk={onClose}
        onCancel={onClose}
        width={"95vw"}
        key={challenge?.challengeId}
      >
        {contextHolder}
        {challenge && challenge.challengeEntries && (
          <Table
            columns={columns}
            rowKey="uid"
            dataSource={challenge.challengeEntries}
            className="mobile-table-small"
          />
        )}
      </Modal>
    </>
  );
};

export default ChallengeTable;
