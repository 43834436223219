import { Modal } from "antd";
import { FC, useState } from "react";
import { getCdnUrl } from "src/helpers";
import { PlayCircleOutlined } from "@ant-design/icons";

interface Props {
  replay: string | null;
}
const ReplayVideo: FC<Props> = ({ replay }) => {
  const [open, setOpen] = useState(false);
  console.log(replay);
  return (
    <>
      <PlayCircleOutlined onClick={() => setOpen(true)} />
      <Modal
        title={`Replay`}
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        destroyOnClose
        bodyStyle={{ height: "85vh" }}
        footer={false}
      >
        {open && replay && (
          <video width="100%" height="100%" controls autoPlay={true}>
            <source src={`${getCdnUrl()}${replay}`} type="video/mp4" />
          </video>
        )}
      </Modal>
    </>
  );
};

export default ReplayVideo;
