import { FC, useMemo } from "react";
import { getRandomColor } from "src/helpers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title as ChartTitle,
  Tooltip,
  TooltipItem,
  Legend,
  ChartEvent,
  ActiveElement,
  Chart,
} from "chart.js";
import "chart.js/auto";
import { Bar, Line } from "react-chartjs-2";
// import Zoom from "chartjs-plugin-zoom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ChartTitle,
  Tooltip,
  Legend
  // Zoom
);

const STEP_SIZE = 3;
const MAX_SIZE = 25;
const getOptions = (
  maxTicksLimit: number,
  onClick?: (event: ChartEvent, elements: ActiveElement[], chart: Chart) => void
) => ({
  responsive: true,
  onClick,
  plugins: {
    title: {
      display: true,
      text: "Bonus Cash History",
    },
    tooltip: {
      callbacks: {
        footer: (items: TooltipItem<"line">[]) =>
          items.map((e) => {
            const raw = e.raw as HistoryResponse;
            return `Date: ${raw.created_at} | Gems Sum: ${raw.gems} | Cash Sum: ${raw.cash}`;
          }),
      },
    },
    // zoom: {
    //   zoom: {
    //     wheel: {
    //       enabled: true,
    //     },
    //     pinch: {
    //       enabled: true,
    //     },
    //     mode: "xy",
    //   },
    // },
  },
  interaction: {
    intersect: false,
  },
  parsing: {
    xAxisKey: "created_at",
    yAxisKey: "bonus_cash",
  },
  scales: {
    xAxis: {
      display: true,
      title: {
        display: true,
        text: "Time Elapsed (YYYY-MM-DD)",
      },
      ticks: {
        autoSkip: true,
        source: "data",
        maxTicksLimit,
        stepSize: STEP_SIZE,
      },
    },
    yAxis: {
      display: true,
      title: {
        display: true,
        text: "sum",
      },
    },
  },
});

const BonusGraph: FC<{
  historicalBalance: HistoryResponse[];
  graphType: "Bar" | "Line";
}> = ({ historicalBalance, graphType }) => {
  const dataSet = useMemo(() => {
    const tick = Math.ceil(historicalBalance.length / STEP_SIZE);
    return {
      maxTicksLimit: tick < MAX_SIZE ? tick : MAX_SIZE,
      graphData: {
        labels: historicalBalance.map((e) => e.created_at),
        datasets: [
          {
            data: historicalBalance,
            borderColor: getRandomColor(),
            backgroundColor: getRandomColor(),
            fill: false,
            tension: 0.4,
            label: "Bonus Cash",
          },
        ],
      },
    };
  }, [historicalBalance]);

  const options = getOptions(dataSet.maxTicksLimit);

  if (graphType === "Bar")
    return <Bar options={options as any} data={dataSet.graphData} />;
  return <Line options={options as any} data={dataSet.graphData} />;
};

export default BonusGraph;
