import React from "react";
import { onAuthStateChanged } from "firebase/auth";
import UserBalanceTransactions from "src/components/balanceTransactions";
import AppUsers from "src/components/appUsers/AppUsers";
import { fetchUpsertUser } from "src/features/authentication/userSlice";
import { getOrganizations } from "src/features/orgs/orgSlice";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import { Button, Col, Layout, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Routes, Route, Navigate, Link } from "react-router-dom";
import SignIn from "src/components/auth/SignIn";
import RequireAuth from "src/components/auth/RequireAuth";
import { auth, isTriumphEmployee, Logger } from "src/helpers";
import UserDetails from "./components/userDetail/userDetails";
import BlitzTournamentV2Detail from "./components/tournament/tournamentDetailPage/Blitz";
import BlitzTournament from "./components/tournament/tournamentTable/BlitzTournament";
import { getAllGames, getGames } from "./features/games/gamesSlice";
import MagicLink from "./components/auth/MagicLink";
import AsyncGroupTournament from "./components/tournament/tournamentTable/AsyncGroupTournaments";
import AsyncGroupTournamentPage from "./components/tournament/tournamentDetailPage/AyncGroup";
import SettingsMenu from "./components/settings/SettingsMenu";
import OrgMenu from "./components/organization/OrgMenu";
import OrganizationSettings from "./components/settings/OrganizationSettings";
import MemberSettings from "./components/settings/MemberSettings";
import AppSideMenu from "./components/layout/AppSideMenu";
import SplashPage from "./components/onboarding/SplashPage";
import Join from "./components/auth/Join";

import { ThemeSwitcherProvider } from "./components/theme/ThemeContextInterface";
import CreateGame from "./components/Games/components/CreateGame/createGame";
import InternalPhoneNumbers from "./components/internal/components/internalPhoneNumbers/InternalPhoneNumbersList";
import { onRefresh } from "./shared/config/constants";
import Welcome from "./components/Welcome";
import Search from "./components/Search";
import ChallengeDetailPage from "./components/tournament/tournamentDetailPage/Challenge";

// import GamesDashboard from "./components/Games";
// import PaymentSettings from "./components/settings/PaymentSettings";
// import InternalGames from "./components/internal/components/games/InternalGames";
// import InternalMasterContract from "./components/internal/components/masterContract/InternalMasterContract";
// import OrganizationTransactions from "./components/settings/OrgTransactions";
// import InternalOrgs from "./components/internal/components/orgs/InternalOrgs";
// import BlitzConfigList from "./components/Games/components/Blitz/blitzConfigList";
// import DepositConfigList from "./components/Games/components/DepositDefinition/DepositConfigList";
// import InternalSupport from "./components/internal/components/support/InternalSupport";
// import InternalVersions from "./components/internal/components/version/InternalVersions";
// import PlatformAgreement from "./components/settings/PlatformAgreement";
// import OrganizationContract from "./components/settings/Contract";
// import UserRequireReview from "./components/internal/components/userState/userStateTable";
// import InternalStats from "./components/internal/statistics";
// import AllBalanceTransactions from "./components/internal/components/allBalanceTransactions";
import "./App.scss";
/** type imports */

const { Header, Content, Sider } = Layout;

const LandingPage: React.FC = () => {
  const { listOfGames, loading: gamesListIsLoading } = useAppSelector(
    (state) => state.gameState
  );

  const { user, loading: userIsLoading } = useAppSelector(
    (state) => state.userState
  );

  const stateIsLoading = userIsLoading || gamesListIsLoading;

  const [isOpenGame, setIsOpenGame] = React.useState(false);

  if (!user) {
    return <></>;
  }

  const canShowStats =
    user.activeOrgId && listOfGames.length === 0 && !stateIsLoading;

  if (user.orgIds.length === 0) {
    return <SplashPage />;
  } else if (canShowStats) {
    return (
      <>
        <CreateGame open={isOpenGame} onClose={() => setIsOpenGame(false)} />
        <Row style={{ paddingTop: "25%" }} justify="center">
          <Col span={4}>
            <Button
              type="link"
              icon={<PlusOutlined />}
              onClick={() => {
                setIsOpenGame(true);
              }}
            >
              Add New Game
            </Button>
          </Col>
        </Row>
      </>
    );
  } else {
    return <Navigate replace to="/welcome" />;
  }
};
const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.userState);
  const { orgs } = useAppSelector((state) => state.orgState);
  const { listOfGames } = useAppSelector((state) => state.gameState);

  const [shouldCollapse, setShouldCollapse] = React.useState(false);
  const [initialTheme] = React.useState(
    localStorage?.getItem("theme") || "dark"
  );

  React.useEffect(() => {
    window.addEventListener("beforeunload", onRefresh);
    return () => {
      window.removeEventListener("beforeunload", onRefresh);
    };
  }, []);

  // fetch user admin on auth change
  React.useEffect(() => {
    onAuthStateChanged(auth, async (userRef) => {
      try {
        if (userRef) {
          const user = userRef.toJSON() as FirebaseAuthUser;
          await dispatch(fetchUpsertUser(user));
        } else {
          await dispatch(fetchUpsertUser(null));
        }
      } catch (error) {
        Logger("App.tsx", "onAuthStateChanged", error);
      }
    });
  }, [dispatch]);

  // fetch orgs for this user
  React.useEffect(() => {
    const fetchOrgs = async () => {
      await dispatch(getOrganizations());
    };
    fetchOrgs();
  }, [dispatch, user]);

  // fetch other org info (games / stats) for this user
  React.useEffect(() => {
    const fetchOrgData = async () => {
      if (orgs) {
        await Promise.all([dispatch(getGames()), dispatch(getAllGames())]);
      }
    };
    fetchOrgData();
  }, [dispatch, orgs]);

  return (
    <ThemeSwitcherProvider defaultTheme={initialTheme}>
      <Routes>
        <Route path="/sign-in" element={<SignIn />} />
        <Route path="/magic-link/:email" element={<MagicLink />} />
        <Route path="/join/:token" element={<Join />} />
        <Route
          path="/*"
          element={
            <RequireAuth redirectTo="/sign-in">
              {user && (
                <Layout>
                  <Header className="header">
                    <div className="logo d-flex align-items-center">
                      <Link to="/welcome" className="triumph-logo" />
                    </div>
                    <div className="header-toolbar">
                      <OrgMenu />
                      {/* {listOfGames.length ? <UserSearch /> : null} */}
                      {listOfGames.length ? <Search /> : null}
                      <SettingsMenu />
                    </div>
                  </Header>
                  <Layout>
                    <Sider
                      width={256}
                      breakpoint="md"
                      collapsedWidth={0}
                      collapsed={shouldCollapse}
                      onCollapse={() => {
                        setShouldCollapse(!shouldCollapse);
                      }}
                    >
                      <AppSideMenu setShouldCollapse={setShouldCollapse} />
                    </Sider>
                    <Layout
                      style={{
                        marginLeft: "256px",
                        marginTop: "64px",
                        padding: "16px",
                      }}
                    >
                      <Content className="content-bg">
                        <Routes>
                          <Route path="/" element={<LandingPage />} />
                          <Route path="/welcome" element={<Welcome />} />
                          <Route
                            path="/organization/*"
                            element={
                              <Routes>
                                <Route
                                  path="/"
                                  element={
                                    <Navigate
                                      replace
                                      to="/organization/settings"
                                    />
                                  }
                                />
                                <Route
                                  path="/settings"
                                  element={<OrganizationSettings />}
                                />
                                <Route
                                  path="/members"
                                  element={<MemberSettings />}
                                />
                                <Route
                                  path="internal_phone_numbers"
                                  element={<InternalPhoneNumbers />}
                                />
                                {/* <Route
                                  path="/balances"
                                  element={<PaymentSettings />}
                                />   <Route
                                  path="/balances/:page"
                                  element={<PaymentSettings />}
                                /> 
                                  <Route
                                  path="/platform-agreement"
                                  element={<PlatformAgreement />}
                                />  
                                 {isTriumphEmployee() && (
                                  <>
                                    <Route
                                      path="/contract"
                                      element={<OrganizationContract />}
                                    />
                                    <Route
                                      path="/org-balance-transactions"
                                      element={<OrganizationTransactions />}
                                    />
                                  </>
                                )} */}
                              </Routes>
                            }
                          />
                          <Route path="users/:id" element={<UserDetails />} />
                          {isTriumphEmployee() && (
                            <>
                              <Route
                                path="user_balance_transactions"
                                element={<UserBalanceTransactions />}
                              />
                              {/* replace with v3 when we totally migrate to v3 */}
                              <Route
                                path="games/:game/tournaments/asyncGroup/:id"
                                element={<AsyncGroupTournamentPage />}
                              />
                              <Route
                                path="games/:game/tournaments/asyncGroupv1/:id"
                                element={<AsyncGroupTournamentPage />}
                              />
                              <Route
                                path="games/:game/tournaments/asyncGroupv3/:id"
                                element={<AsyncGroupTournamentPage />}
                              />
                              <Route
                                path="games/:game/tournaments/blitz/:id"
                                element={<BlitzTournamentV2Detail />}
                              />
                              <Route
                                path="tournaments/async-group"
                                element={<AsyncGroupTournament />}
                              />
                              <Route
                                path="tournaments/blitz"
                                element={<BlitzTournament />}
                              />
                              <Route
                                path="games/:game/challenges/:id/users/:userId/tournament/:tournamentId"
                                element={<ChallengeDetailPage />}
                              />
                            </>
                          )}
                          {/* <Route
                            path="games/:game/config/:config"
                            element={<GamesDashboard />}
                          />
                          <Route
                            path="games/:game/stats"
                            element={<GamesDashboard />}
                          /> */}
                          <Route path="docusign" element={<AppUsers />} />

                          {/* {isTriumphEmployee() && (
                            <Route
                              path="/internal/*"
                              element={
                                <Routes>
                                  <Route
                                    path="games/*"
                                    element={
                                      <Routes>
                                        <Route
                                          path="/"
                                          element={<InternalGames />}
                                        />
                                        <Route
                                          path="/:game/*"
                                          element={
                                            <Routes>
                                              <Route
                                                path="blitz_tournament_definitions"
                                                element={
                                                  <BlitzConfigList
                                                    isAdmin={true}
                                                  />
                                                }
                                              />
                                              <Route
                                                path="deposit_definitions"
                                                element={
                                                  <DepositConfigList
                                                    isAdmin={true}
                                                  />
                                                }
                                              />
                                            </Routes>
                                          }
                                        />
                                      </Routes>
                                    }
                                  ></Route>
                                  <Route
                                    path="orgs"
                                    element={<InternalOrgs />}
                                  />
                                  <Route
                                    path="balance_transactions"
                                    element={<AllBalanceTransactions />}
                                  ></Route>
                                  <Route
                                    path="stats"
                                    element={<InternalStats />}
                                  />
                                  <Route
                                    path="master_contract"
                                    element={<InternalMasterContract />}
                                  />

                                  <Route path="users" element={<AppUsers />} />
                                  <Route
                                    path="support"
                                    element={<InternalSupport />}
                                  />
                                  <Route
                                    path="version"
                                    element={<InternalVersions />}
                                  />
                                  <Route
                                    path="user_require_review"
                                    element={<UserRequireReview />}
                                  />
                                </Routes>
                              }
                            />
                          )} */}
                        </Routes>
                      </Content>
                    </Layout>
                  </Layout>
                </Layout>
              )}
            </RequireAuth>
          }
        />
      </Routes>
    </ThemeSwitcherProvider>
  );
};

export default App;
