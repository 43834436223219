import {
  Collapse,
  Table,
  TableColumnsType,
  TablePaginationConfig,
  Typography,
} from "antd";
import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { anonUpgradesConverter } from "src/converters";
import { db, generateFirestorePath } from "src/helpers";
import GameNameV2 from "src/shared/components/GameName/gameNameV2";
import {
  dateFormatter,
  formatMillisecondsDuration,
} from "src/shared/config/constants";

interface Props {
  userId: string;
  expandedView: boolean;
}
const AnonUpgrades: FC<Props> = ({ userId, expandedView }) => {
  const [anonUpgradesList, setAnonUpgradesList] = useState<
    AnonUpgrade[] | null
  >(null);
  useEffect(() => {
    setAnonUpgradesList(null);
    const userRef = collection(db, generateFirestorePath("appUsers"));

    const userDoc = doc(userRef, userId);

    const anonUpdagradsRef = collection(
      userDoc,
      generateFirestorePath("anonUpgrades")
    ).withConverter(anonUpgradesConverter);

    const unsubAnonCol = onSnapshot(anonUpdagradsRef, (colSnap) => {
      const defs: typeof anonUpgradesList = [];
      for (const anonDefSnap of colSnap.docs) {
        const anonDef = anonDefSnap.data();
        defs.push(anonDef);
      }
      setAnonUpgradesList(defs);
    });

    return () => {
      unsubAnonCol();
    };
  }, [userId]);

  const pageinationConfig: TablePaginationConfig = {
    defaultPageSize: expandedView ? 10 : 20,
    className: "normal-option-size",
  };

  const columns: TableColumnsType<AnonUpgrade> = [
    {
      title: "Anon Id",
      dataIndex: "anonId",
      key: "anonId",
    },
    {
      title: "Game",
      dataIndex: "gameId",
      key: "gameId",
      render(value) {
        return <GameNameV2 id={value} />;
      },
    },
    {
      title: "Org Id",
      dataIndex: "orgId",
      key: "orgId",
    },

    {
      title: "Upgrade Duration",
      dataIndex: "upgradeDuration",
      key: "upgradeDuration",
      sorter: (a, b) => a.upgradeDuration - b.upgradeDuration,
      render: (value) => formatMillisecondsDuration(value),
    },
    {
      title: "UpgradedAt",
      dataIndex: "upgradedAt",
      defaultSortOrder: "descend",
      key: "upgradedAt",
      render: (value) => dateFormatter(value),
      sorter: (a, b) => a.upgradedAt - b.upgradedAt,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a, b) => a.createdAt - b.createdAt,
      render: (value) => dateFormatter(value),
    },
  ];
  if (anonUpgradesList === null) return <></>;
  return (
    <Collapse ghost>
      <Collapse.Panel
        header={<Typography.Title level={5}>Anon Upgrades</Typography.Title>}
        key="1"
      >
        <Table
          columns={columns}
          rowKey="anonId"
          dataSource={anonUpgradesList}
          pagination={pageinationConfig}
          className="mobile-table-small bottom"
        />
      </Collapse.Panel>
    </Collapse>
  );
};

export default AnonUpgrades;
