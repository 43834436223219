import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { axiosInstanceV2 } from "src/helpers";
import {
  Divider,
  Empty,
  Row,
  Skeleton,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import Currency from "src/shared/components/currency/Currency";
import Link from "antd/lib/typography/Link";
import IntermediateScoreGraph from "src/components/tournament/tournamentDetailPage/IntermediateScore";
import { useAppDispatch, useAppSelector } from "src/app/hooks";
import UserInfoExpanded from "src/components/balanceTransactions/userInfo/UserInfoExpanded";
import ChallengeTable from "src/components/balanceTransactions/ChallengeEntry/ChallengeTable";
import TriumphPage from "src/shared/layout/TriumphPage";
import { getAppUserPublicDetails } from "src/features/appUsersPublic/appUserPublicSlice";

const ChallengeDetailPage = () => {
  const { game, id, userId, tournamentId } = useParams();
  const dispatch = useAppDispatch();

  const [tournaments, setTournaments] = useState<HistoricChallengeResult>();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<string | null>(null);
  const [selectedChallenge, setSelectedChallenge] =
    useState<HistoryChallengeEntry | null>(null);
  const { user: activeUser } = useAppSelector((state) => state.userState);

  useEffect(() => {
    if (userId) {
      dispatch(getAppUserPublicDetails(userId));
    }
  }, [dispatch, userId]);

  const getTournamentByChallengeId = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstanceV2.get<HistoricChallengeResult>(
        `/challenges/admin/tournaments?challengeId=${id}&userId=${userId}`
      );

      if (response.status === 200) {
        setTournaments(response.data);
        setIsLoading(false);
        return response.data;
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [userId, id]);

  useEffect(() => {
    getTournamentByChallengeId();
  }, [getTournamentByChallengeId]);

  const columns: TableColumnsType<HistoryChallengeEntry> = [
    {
      title: "Challenge Id",
      dataIndex: "challengeId",
      key: "challengeId",
      render: (id, record) => (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            setSelectedChallenge(record);
          }}
        >
          {id}
        </Link>
      ),
    },
    {
      title: "User",
      dataIndex: "userId",
      key: "userId",
      render: (id) => (
        <Link
          onClick={(e) => {
            e.stopPropagation();
            setSelectedUser(id);
          }}
        >
          {id}
        </Link>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Cash Prize",
      dataIndex: "cashPrize",
      key: "cashPrize",
      render: (value) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Bonus Cash Prize",
      dataIndex: "bonusCashPrize",
      key: "bonusCashPrize",
      render: (value) => (
        <Currency value={value} currency="cents-to-dollar" prefix="$" />
      ),
    },
    {
      title: "Gems Prize",
      dataIndex: "gemsPrize",
      key: "gemsPrize",
    },
    {
      title: "Crowns Prize",
      dataIndex: "crownsPrize",
      key: "crownsPrize",
    },
    {
      title: "Rank",
      dataIndex: "rank",
      key: "rank",
    },
    {
      title: "Total Players",
      dataIndex: "totalPlayers",
      key: "totalPlayers",
    },
  ];

  if (!id || !game || !userId || !tournamentId) return <Empty />;
  if (isLoading) return <Skeleton avatar paragraph />;
  if (!tournaments) return <Empty description="Failed to fetch data" />;
  return (
    <>
      <UserInfoExpanded
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <ChallengeTable
        onSuccessFullRecredit={async () => {
          const data = await getTournamentByChallengeId();
          setSelectedChallenge((value) => {
            return (
              data?.entries.find((f) => f.challengeId === value?.challengeId) ||
              null
            );
          });
        }}
        onClose={() => setSelectedChallenge(null)}
        challenge={selectedChallenge}
      />
      <Row>
        <Typography.Title level={4}>Challenges</Typography.Title>
      </Row>
      <Table
        columns={columns}
        rowKey="uid"
        dataSource={tournaments.entries || []}
        className="mobile-table-small"
      />
      <Divider />
      {activeUser && activeUser.activeOrgId && (
        <IntermediateScoreGraph
          tournamentId={tournamentId}
          gameId={game}
          orgId={activeUser.activeOrgId}
        />
      )}

      <Divider />
    </>
  );
};
const component = () => {
  return (
    <TriumphPage>
      <ChallengeDetailPage />
    </TriumphPage>
  );
};
export default component;
